<template>
  <transition>
    <div class="modal show"  tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">    
        <div class="modal-content shadow">
          <div class="modal-header">
            <h5 v-if="type === 'image'" class="modal-title">Aperçu</h5>
            <h5 v-else-if="type === 'comment' || type === 'edit'" class="modal-title">Commentaire</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeModal($event)">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div v-if="type === 'image'" class="modal-body">
            <v-lazy-image :src="$protocolServer + '://' + $urlServer + '/backend/get/image/voir/' + image " alt="Zoom" />
          </div>
          <div v-else-if="type ==='comment'" class="modal-body">
            <div v-for="commentaire in json" v-bind:key=commentaire._ID v-html="commentaire"/>
            <div class="clear-both w-100 navModalDiv">
              <div @click="action('prev')" class="navModal blue d-inline-block w-50">⏮️</div>
              <div @click="action('next')" class="navModal blue d-inline-block w-50">⏭️</div>
            </div>
          </div>
          <div v-else-if="type ==='edit'" class="modal-body">
            <textarea v-for="commentaire in json" v-bind:key=commentaire._ID v-model="text" :run="getComment(commentaire)"/>
            <div style="text-align:center">  
              <div v-if="error === false" class="blue">Commentaire sauvegardé</div>
              <div v-else-if="error === true" class="red">Erreur lors de la sauvegarde</div>
              <button @click="chkComment($event)" class="shadow button">Sauvegarder</button>
            </div>
          </div>          
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import VLazyImage from 'v-lazy-image'
import axios from 'axios'
export default {
  name: 'modal',
  components: {    
    VLazyImage    
  },
  props: {
    image : {
      type: String,
      required: false,
      default: "7447"
    },
    type : {
      type: String,
      required: false,
      default: "image"
    },
    comment : {
      type: String,
      required: false,
      default: null
    },
  },
  data() {         
    return {
      isShown: false,
      json: null,
      error: null,
      text: '',
      proprio: null
    }
  },
  mounted() {    
      this.isShown = true   
      this.getProprio() 
      if(this.type === "comment") {
        axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/image/comment/' + this.comment).then(response => (this.json = response.data.commentaire))  
      }
      else if(this.type === "edit") {
        axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/image/comment/' + this.comment + '/raw').then(response => (this.json = response.data.commentaire))  
      }
  },
  methods: {
    closeModal(e) {
      e.target.parentNode.parentNode.parentNode.parentNode.style.display="none"
      this.isShown = false
      this.$emit("destroyModal")
    },
    getComment(text) {
      if(text !== null && this.text == '') {
        this.text = text        
      }
    },
    chkComment(e) {
      if( this.text !== null && this.text.trim() != "") {
        this.saveComment()
      } 
      else {
          this.error = true          
      }     
      e.preventDefault()
    },    
    saveComment() {
      var apiUrl = "/backend/get/image/postcomment"
      fetch(apiUrl,{
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({proprio: this.proprio,image: this.comment, text: this.text})
        })
        .then(res => res.json())
        .then(res => {
          if(res.post != "ok") {
            this.error = true                        
          }
          else if(res.post === "ok") {            
            this.error = false
          }                       
        });
    },
    getProprio: function() {
      this.proprio = localStorage.getItem('login') 
    },
    action: function(action) {
      if(action === "prev") {
        this.$emit("prev")
      }
      else if (action === "next") {
        this.$emit("next")
      }
    }
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.show {
  display:block;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

img {
  max-width:100%;
  max-height:100%;
  margin: 0 auto;
  display: block;
}

.modal-header {
  border-bottom:0;
  color: #007bff;
  font-weight:bold;
}

.v-lazy-image {
  filter: blur(10px);
  transition: filter 0.7s;
}
.v-lazy-image-loaded {
  filter: blur(0);
}

textarea {
  width:100%;
  min-height:8em;
}

.button {
  color: #007bff;
  border: none;
  background-color: #eee;
  padding: 10px 32px;
  margin-bottom:5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
}

.navModalDiv {
  position:relative;    
  padding-top:10px;
}

.navModal {
  font-weight:bold;
  cursor: pointer;
  font-size:1.2em;
}

.blue {
  color:#007bff;
}

.red {
  color: darkred;
}

</style>
