<template>
  <div>
    <vue-headful
        :title="'Install-HC - L\'Installation Home-cinema de ' + cleanProprio + ' - Les photos'" 
        description="Les photos de son Installation Home-Cinema"
    />
    <checkDisplayed :proprio="proprio" @isDisplayed="isDisplayed"/>
    <div v-if="notHidden === false">
      <p>
        Non disponible pour le moment !
      </p>
    </div>
    <div v-else class="installation container-fluid min-vh-100">            
      <Menu/>
      <Modal v-if="readComment === true" @destroyModal="destroyModal" :image="idImg" type="comment" :comment="idImg" @prev="prevModal" @next="nextModal"/>   
      <div class="picture clickedDiv" :style="'height:' + actualSize + 'px;background-image:url(\'' + firstImg + '\');'"  tabindex="0" @keydown.esc="onEscape">        
        <div class="debug">
          <h1>{{ actualSize }}px ! {{ txt }}</h1>
          <p>Url : {{ firstImg }}, target : {{ target }}</p>
        </div>
        <div class="d-inline-block w-50 h-100 left" v-on:click="prev">
        </div>
        <div class="d-inline-block w-50 h-100 right" v-on:click="next">          
        </div>
        <div v-if="isCommented.includes(idImg)" class="commentLink" @click="getComment">📝</div>
      </div>
      <div class="galleryScroll fixed-bottom w-100">
        <div v-for="galerie in json" v-bind:key=galerie._ID class="d-inline-block clickedDiv" :id="galerie.ID" v-on:click="select($event)">      
          <v-lazy-image :src="$protocolServer + '://' + $urlServer + '/backend/get/image/apercevoir/' + galerie.ID" :alt="'Installation de ' + galerie.proprio" :class="{ 'blur': idImg === galerie.ID}"/>        
        </div>
      </div>
    </div>  
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import VLazyImage from 'v-lazy-image'
import Menu from '@/components/Menu.vue'
import Modal from '@/components/Modal.vue'
export default { 
  name: 'installation',
  components: {
    Menu,
    VLazyImage,
    Modal
  },
  data() {         
    return {
      json: null, 
      firstImg: null,
      idImg: null,
      windowHeight: 0,
      windowWidth: 0,
      txt: '',
      actualSize: 0,
      target: '',
      imgArray: [],
      notHidden: true,
      isCommented: [],
      readComment: false
    }    
  },
  props: {
    proprio: {
        type: String,
        default: null
    }
  },
  computed: {
    cleanProprio: function() {
      return this.proprio.replace(/___/gi,' ')
    }
  },
  methods: {
    getComment: function() {
      this.readComment = true
    },
    destroyModal: function() {
      this.readComment = false
    },
    isDisplayed: function(e) {
      this.notHidden = e
    },
    select: function(event) {
      this.target = event.currentTarget.id;
      this.firstImg = this.$protocolServer + "://" + this.$urlServer + "/backend/get/image/voir/" + event.currentTarget.id + "/4";      
      this.idImg = event.currentTarget.id;
      var pos = this.imgArray.indexOf(this.idImg);
      //alert('Décalage de  '+ (pos * 133));      
      //document.getElementsByClassName('galleryScroll')[0].scrollBy(((this.imgArray.length - 1) * -133), 0);
      document.getElementsByClassName('galleryScroll')[0].scroll({left: (pos * 133 - this.windowWidth /2 + 66), behavior: 'smooth'});     
      //alert(this.target); // returns 'foo'
    },
    prevModal: function() {
      this.destroyModal()
      this.prev()
    },
    nextModal: function() {
      this.destroyModal()
      this.next()
    },
    next: function() { 
      var pos = this.imgArray.indexOf(this.idImg);
      //alert(this.idImg + ' position : ' + pos + ' / ' + (this.imgArray.length - 1));
      if (pos < (this.imgArray.length - 1)) {
        this.firstImg = this.$protocolServer + "://" + this.$urlServer + "/backend/get/image/voir/" + this.imgArray[pos + 1] + "/4";      
        this.idImg = this.imgArray[pos + 1];
        //alert(pos + '-' + this.windowWidth)
        if((pos * 133 + 133) > (this.windowWidth /2)) {
          document.getElementsByClassName('galleryScroll')[0].scrollBy({left: 133, behavior: 'smooth'});
        }
      }  
      else {
        this.firstImg = this.$protocolServer + "://" + this.$urlServer + "/backend/get/image/voir/" + this.imgArray[0] + "/4";      
        this.idImg = this.imgArray[0];     
        document.getElementsByClassName('galleryScroll')[0].scrollBy({left: ((this.imgArray.length - 1) * -133), behavior: 'smooth'});
      }    
    },
    prev: function() { 
      var pos = this.imgArray.indexOf(this.idImg);
      //alert(this.idImg + ' position : ' + pos + ' / ' + (this.imgArray.length - 1));
      if (pos < (this.imgArray.length ) && pos != 0) {
        this.firstImg = this.$protocolServer + "://" + this.$urlServer + "/backend/get/image/voir/" + this.imgArray[pos - 1] + "/4";      
        this.idImg = this.imgArray[pos - 1];     
        if((this.imgArray.length * 133 - pos * 133) > (this.windowWidth /2)) {
          document.getElementsByClassName('galleryScroll')[0].scrollBy({left: -133, behavior: 'smooth'});
        }
      }  
      else {
        // Nothing for the moment !
      }    
    },
    onEscape() {
      this.$router.go(-1)
    }

  },    
  watch: {
    windowHeight(newHeight, oldHeight) {
     this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    }
  },  
  mounted() {    
    this.actualSize = (window.innerHeight-117)    
    this.windowWidth = window.innerWidth;
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowHeight = window.innerHeight;
        this.windowWidth = window.innerWidth;
        this.actualSize = (window.innerHeight-117)
      });
    })  
    axios.get(this.$protocolServer + '://' + this.$urlServer + '/backend/get/installation/photos/proprio/' + this.proprio)
    //.then(response => (this.json = response.data.galerie))
    .then(response => {this.json = response.data.galerie.pix;
      let vm = this;       
      this.json.forEach(function(galerie){
        if(vm.firstImg == null) {
          vm.firstImg = vm.$protocolServer + '://' + vm.$urlServer + "/backend/get/image/voir/" + galerie.ID + "/4";
          vm.idImg = galerie.ID;          
        }
        vm.imgArray.push(galerie.ID);
        if(galerie.isCommented === true){
          vm.isCommented.push(galerie.ID);
        }
      })      
    }) 
  }
}

</script>

<style scoped>
.installation {
  margin:0 auto;
  background-color:black;
  width:100%;  
  padding:0
}

.blur {
  filter: grayscale(0%) !important;    
  transform: scale(1.2) !important;
  box-shadow: -1px 2px 10px 1px rgba(200, 200, 200, .7);
  vertical-align: middle;  
}

.debug {
  display: none;
}

.galleryScroll {
  height:117px;  
  overflow-y: hidden;
  overflow-x:auto;  
  white-space: nowrap;
}

.clickedDiv {
  cursor:pointer;  
  overflow:hidden;
}

.clickedDiv:focus {
  outline:none;
}

.clickedDiv img {
  filter: grayscale(95%);    
  vertical-align: bottom;
}

.picture {
  position:absolute;
  top:0%;
  width:100%;
  overflow:hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.v-lazy-image {
  filter: blur(10px);
  transition: filter 0.7s;
}
.v-lazy-image-loaded {
  filter: blur(0);
}
 
/* .left {
  background: rgba(240, 0, 0, .3)
}
.right {
} 
 */
.commentLink {   
  position: absolute; 
  cursor: pointer;
  font-size:36px;
  color : #eee;
  width:100%;
  text-align: right;
  bottom:20px;
  right:10px;
  font-weight: bold;
  font-family: Verdana,Geneva,sans-serif; 
  z-index:200;
  transition-property: font-size;
  transition-duration: .5s;
  transition-delay: .5s;
  animation: blinker 1s linear 3;
}

@keyframes blinker {
  50% {
    opacity: 0;    
  }
}

.commentLink:hover {
  font-size:48px;
}

</style>